import React from 'react';
import { fetchComparisonData, fetchResultsData, ResultsDataContext, fillShortResultsToQuestionTree } from '../DataContext';

import Results from './Results';
import CityControls from './CityControls';
import FetchingDataBar from './FetchingDataBar';
import BasicAreaResults from './BasicAreaResults';
import SchoolResultsSemesterPicker from './SchoolResultsSemesterPicker';


const buildResultsUrl = (city, targetAudience, args) => `/api/v1/results/city/${city}/${targetAudience}?${args}`

const buildArguments = (controlsState) => {
  const separators = [];
  const locale = window.CURRENT_LOCALE || 'fi'
  const roundedSemester = controlsState.selectedSemester.split("-")[0]
  let args = `locale=${locale}&semester=${controlsState.selectedSemester}&format=short`
  let comparisonArgs = `locale=${locale}&semester=${roundedSemester}&format=short`

  if (controlsState.selectedView === "gradesSeparated") {
    separators.push("grade");
  }

  if (controlsState.separateGenders) {
    separators.push("sex");
  }

  if (separators.length) {
    args += `&separated_by=${separators.join(",")}`
    comparisonArgs += `&separated_by=${separators.join(",")}`
  }

  return [args, comparisonArgs]
}


export default class extends React.Component {

  static defaultProps = {
    city: null,
    city_names: [],
    city_slug: null,
    country_code: null,
    target_audience: null,
    available_semesters: null,
    questions_tree: null,
    my_schools: [],
    semester: null,
  };

  constructor(props) {
    super(props);


    this.exportView = this.exportView.bind(this)
    this.updateView = this.updateView.bind(this)
    this.fetchMainResults = this.fetchMainResults.bind(this)

    this.resultsDataContext = {
      freetextAnswers: {},
      fetchFreetextAnswers: () => { },
      showFreetextQuestions: false,
      openAccordionsInitially: false,
    }

    this.state = {
      mainResults: null,
      currentMainArgs: null,
      fetchingMainData: true,
      fetchingComparisonData: false,
      changed: false,
      comparisons: []
    }
  }

  exportView(controlsState) {
    console.log("City.exportView()", controlsState);
    const [mainArgs, comparisonArgs] = buildArguments(controlsState)
    const url = buildResultsUrl(this.props.city_slug, this.props.target_audience, mainArgs) + "&output=excel"
    window.open(url, "_blank")
  }

  updateView(controlsState) {
    console.log("City.updateView()", controlsState);
    const [mainArgs, comparisonArgs] = buildArguments(controlsState)

    this.setState({
      fetchingMainData: true,
      changed: false
    })

    if (this.state.mainResults !== null || mainArgs !== this.state.currentMainArgs) {
      this.fetchMainResults(mainArgs)
    } else {
      console.log("No need to re-fetch main results")
      this.setState({ fetchingMainData: false })
    }

    // fetch the comparison data
    this.setState({ fetchingComparisonData: true })
    const comparisonPromises = controlsState.selectedComparisons.map(({ id, type }) => {
      if (type === "country") {
        return fetchComparisonData("country", this.props.target_audience, id, comparisonArgs, true)
      } else if (type === "time") {
        // we only support "last_year" right now
        if (id !== "last_year") {
          console.error("no comparison to past outside of last year yet", id)
        }
        // Javascript actually handles this parseInt("2020-1") => 2020 ...
        const comparisonData = `city,${this.props.city_slug},${parseInt(this.props.semester) - 1}`
        return fetchComparisonData(id, this.props.target_audience, comparisonData, comparisonArgs, true)
      } else if (type === "school") {
        return fetchComparisonData("school", this.props.target_audience, parseInt(id), comparisonArgs, true)
      } else {
        console.error(`Unknown comparison called in CityResults: id=${id}, type=${type}`)
      }
    }
    )
    Promise.all(comparisonPromises).then((resolvedResults) => {
      this.setState({
        fetchingComparisonData: false
      })
      const results = resolvedResults.map((results) => {
        const school = this.props.my_schools.find(({ id }) => id === results.queryId)
        const schoolName = school ? school.name : (results.comparisonType == "country" ? I18N._("country") : I18N._("Last year"))
        if (!results.id) {
          results.id = results.queryId
        }
        return { schoolName, ...results }
      })
      this.setState({ comparisons: results, fetchingComparisonData: false })
    })

  }

  fetchMainResults(args) {
    fetchResultsData(buildResultsUrl(this.props.city_slug, this.props.target_audience, args))
      .then((obj) => {
        obj.results = fillShortResultsToQuestionTree(this.props.questions_tree, obj.results)
        this.setState({
          mainResults: obj,
          currentMainArgs: args,
          fetchingMainData: false
        })
      }).catch(err => {
        this.setState({
          fetchingMainData: false,
          fetchingComparisonData: false
        })
      })
  }

  render() {

    const comparisons = this.state.comparisons
      .filter(({ error }) => error !== true)
      .map((comparison) => {
        return {
          data: comparison,
          with: comparison.schoolName,
          name: comparison.schoolName
        }
      })

    const allLoadingComplete = !(this.state.fetchingMainData || this.state.fetchingComparisonData)

    return (
      <ResultsDataContext.Provider value={this.resultsDataContext}>

        <SchoolResultsSemesterPicker chosenSemester={this.props.semester} semesters={this.props.available_semesters} results_url_template={this.props.results_url_template} />

        {this.state.mainResults !== null && <BasicAreaResults mainResults={this.state.mainResults} {...this.props} />}

        <CityControls {...this.props} comparisons={this.state.comparisons} updateCallback={this.updateView} ready={allLoadingComplete} exportCallback={this.exportView} />

        {
          allLoadingComplete ?
            <>
              {this.state.mainResults !== null &&
                <Results
                  resultsSet={this.state.mainResults}
                  comparisons={comparisons}
                  mainSourceName={this.props.city_names[0]}
                  skipFirstArea={true}
                />
              }
            </>
            :
            <FetchingDataBar />
        }

      </ResultsDataContext.Provider>
    )
  }
}