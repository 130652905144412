import AnswerRow from './components/v3/AnswerRow'
import AnswerRowAccordion from './components/v3/AnswerRowAccordion'
import SchoolResults from './components/v3/SchoolResults'
import CityResults from './components/v3/CityResults'
import scrollTo from './scrollTo'
import { handle__react_renders } from './rendering'


// polyfill fetch
import 'whatwg-fetch'
// inline polyfills
require('../polyfills.es6')


const COMPONENTS = {
  AnswerRow,
  AnswerRowAccordion,
  SchoolResults,
  CityResults,
}

function _findAncestorWithClass(elem, className) {
  if (elem.classList.contains(className)) {
    return elem
  }
  if (elem.parentElement) {
    return _findAncestorWithClass(elem.parentElement, className)
  }
  return null
}

function toggleExpandGraph(event) {
  const elem = _findAncestorWithClass(event.target, "expandable-graph")
  elem.classList.toggle("expanded")
  setTimeout(() => {
    scrollTo(elem.offsetTop, 400)
  }, 100)
}

export default (opts) => {
  handle__react_renders(COMPONENTS);

  const expandGraphButtons = document.getElementsByClassName("toggle-graph-zoom-button")
  for (let btn of expandGraphButtons) {
    btn.onclick = toggleExpandGraph
  }
  const graphs = document.getElementsByClassName("expandable-graph")
  for (let graph of graphs) {
    graph.onclick = toggleExpandGraph
  }
}
